import { FC } from 'react';
import { IconComponentProps } from './icon.model';

export const BudgetIcon: FC<IconComponentProps> = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={24}
		height={24}
		fill="#FFFFFF"
		className={className}
	>
		<path d="M21.09 16.546H2.91a.909.909 0 1 0 0 1.818h18.18a.909.909 0 1 0 0-1.819Zm0 3.636H2.91a.91.91 0 0 0 0 1.818h18.18a.909.909 0 0 0 0-1.818ZM6.546 7.455a.909.909 0 1 0 0 1.818.909.909 0 0 0 0-1.818ZM19.273 2H4.727A2.727 2.727 0 0 0 2 4.727V12a2.727 2.727 0 0 0 2.727 2.727h14.546A2.728 2.728 0 0 0 22 12V4.727A2.727 2.727 0 0 0 19.273 2Zm.909 10a.91.91 0 0 1-.91.91H4.728a.91.91 0 0 1-.909-.91V4.727a.91.91 0 0 1 .91-.909h14.545a.91.91 0 0 1 .909.91V12ZM12 5.636a2.727 2.727 0 1 0 0 5.455 2.727 2.727 0 0 0 0-5.455Zm0 3.637a.91.91 0 1 1 0-1.819.91.91 0 0 1 0 1.819Zm5.454-1.818a.909.909 0 1 0 0 1.817.909.909 0 0 0 0-1.817Z" />
	</svg>
);
