import styles from './header-offline.module.css';

export const HeaderOfflineLayout = () => {
	return (
		<div className={styles.logo}>
			<p>🏛</p>
			<p>finance</p>
		</div>
	);
};
